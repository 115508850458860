@import url(https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
@font-face {
  font-family: "MyriadProLight";
  src: local("MyriadPro-Light"),
    url(/static/media/MyriadPro-Light.1d169f60.otf) format("opentype");
}

@font-face {
  font-family: "MyriadProRegular";
  src: local("MyriadPro-Regular"),
    url(/static/media/MYRIADPRO-REGULAR.6c518fcd.OTF) format("opentype");
}

@font-face {
  font-family: "MyriadProSemibold";
  src: local("MyriadPro-Semibold"),
    url(/static/media/MYRIADPRO-SEMIBOLD.662f3601.OTF) format("opentype");
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.App {
  height: 100%;
}

a {
    text-decoration: none;
    color: #000;
}

.main-box {
    height: 100%;
}
.dashboard_card_header {
    padding: 16px;
    padding-bottom: 0;
}

.dashboard_card_header span.MuiCardHeader-title {
    font-size: 1em;
    font-weight: 800;
}

.dashboard_card {
    margin-bottom: 2em;
}
.pyc-box {
    height: 100%;
}

.pyc-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.client-children-clickable {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}
.login-box {
    height: 100%;
}

.login-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .login-form {
      width: 350px;
      margin: 0 auto;
      border: 1px solid #ddd;
      padding: 2rem;
      background: #fff;
      border-radius: 5%;
  }
  
  .login-form-group {
      margin-bottom: 1rem;
      text-align: center;
  }
  
  .login-form-group Button {
      margin-top: 1rem;
  }
  
  .login-form-header {
      text-align: center;
      margin-bottom: 2rem;
  }
  
  .login-form-footer {
      margin-top: 2rem;
      text-align: center;
  }
