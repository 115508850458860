.login-box {
    height: 100%;
}

.login-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .login-form {
      width: 350px;
      margin: 0 auto;
      border: 1px solid #ddd;
      padding: 2rem;
      background: #fff;
      border-radius: 5%;
  }
  
  .login-form-group {
      margin-bottom: 1rem;
      text-align: center;
  }
  
  .login-form-group Button {
      margin-top: 1rem;
  }
  
  .login-form-header {
      text-align: center;
      margin-bottom: 2rem;
  }
  
  .login-form-footer {
      margin-top: 2rem;
      text-align: center;
  }