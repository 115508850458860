.dashboard_card_header {
    padding: 16px;
    padding-bottom: 0;
}

.dashboard_card_header span.MuiCardHeader-title {
    font-size: 1em;
    font-weight: 800;
}

.dashboard_card {
    margin-bottom: 2em;
}