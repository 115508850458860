@font-face {
  font-family: "MyriadProLight";
  src: local("MyriadPro-Light"),
    url("./fonts/MyriadPro-Light.otf") format("opentype");
}

@font-face {
  font-family: "MyriadProRegular";
  src: local("MyriadPro-Regular"),
    url("./fonts/MYRIADPRO-REGULAR.OTF") format("opentype");
}

@font-face {
  font-family: "MyriadProSemibold";
  src: local("MyriadPro-Semibold"),
    url("./fonts/MYRIADPRO-SEMIBOLD.OTF") format("opentype");
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.App {
  height: 100%;
}
